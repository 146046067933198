import React from "react";
import { navigateTo } from "gatsby-link";
import Layout from "components/layout";
import Section from "containers/section";
import Column from "containers/column";
import Form from "components/form";
import Title from "components/title";
import Button from "components/button";
import { theme } from "constants/theme";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {
    return (
      <Layout headerTheme="dark">
        <Section padding="50px 5vw 0">
          <Column>
            <Title as="h1" color={theme.colors.base} size="giant">
              We would love for you to stay.
            </Title>
          </Column>
        </Section>
        <Section columns="1fr 2fr">
          <Column>
            <Title as="h5" size="small">
              Location:
            </Title>
            <a
              href="https://goo.gl/maps/3jG7npw7q5uLwtrPA"
              target="_blank"
              rel="noreferrer"
            >
              <p>
                96A Bay Rd,
                <br />
                Blue Bay, NSW
                <br />
                2261, Australia
                <br />
              </p>
            </a>
            <br />
            <Title as="h5" size="small" mar>
              Contact:
            </Title>
            <p>
              <a href="tel:0412959075">+61 412 959 075</a>
              <br />
              <a href="mailto:nestatbluebay@gmail.com">
                nestatbluebay@gmail.com
              </a>
            </p>
          </Column>
          <Column>
            <Title as="p" size="small">
              Book via{" "}
              <a href="https://www.airbnb.com.au/rooms/44395601?s=67&unique_share_id=8a786bd9-a1e8-42be-9a37-83a8ebb133f8"
                target="_blank" rel="norefferer">Airbnb</a> or make an enquiry via the form below.
            </Title>
            <Button
              url="https://www.airbnb.com.au/rooms/44395601?s=67&unique_share_id=8a786bd9-a1e8-42be-9a37-83a8ebb133f8"
              text="See availability"
              margin="2rem 0"
              dark
            />
            <Form />
          </Column>
        </Section>
      </Layout>
    );
  }
}
